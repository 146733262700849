<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Campaign Needs Logs (Client)
        </h2>
        <div class="mt-4 bg-white p-4">
        <div class="mt-4 ">
            <div class="mb-4">
                <div class="row">
                    <div class="col-lg-2 col-md-3">
                        <h6>Start Date-Time</h6>
                        <div class="wrap">
                            <ejs-datetimepicker :strictMode="true" :placehoder="'Select Start Date and Time'" v-model="filters.start_date" ></ejs-datetimepicker>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3">
                        <h6>Stop Date-Time</h6>
                        <div class="wrap">
                            <ejs-datetimepicker :strictMode="true" :min="filters.start_date" :placehoder="'Select Stop Date and Time'" v-model="filters.stop_date" ></ejs-datetimepicker>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 34px;">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" @click="search" style="color: white; background-color: rgb(56, 56, 56);" >
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ejs-grid ref="grid"  :dataSource="data" :allowPaging="true" :allowResizing="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :allowFiltering="true"
                  :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings"
                  :filterSettings="filterSettings">
            <e-columns>

                <e-column field='date' minWidth='8' width='50' headerText="Date"></e-column>
                <e-column field='hour' minWidth='8' width='50' headerText="Hour"></e-column>
                <e-column field='message' headerText='Message'  minWidth='8' width='50'></e-column>
                <e-column field='url' headerText='Url'  minWidth='8' width='50'></e-column>
                <e-column field='count' headerText='Count'  minWidth='8' width='50'></e-column>

            </e-columns>

            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="count" format="N" :footerTemplate='footerSum'></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>

        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { GridPlugin, PdfExport, ExcelExport, Toolbar, Aggregate,Sort, Filter,Page,Group, } from '@syncfusion/ej2-vue-grids';
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";

Vue.use(GridPlugin);
Vue.use(DateTimePickerPlugin);

export default {
    name: "needsLogReport.js",
    data() {
        return {

            filters: {
                start_date: new Date(new Date().setHours(0, 0, 0, 0)),
                stop_date: "",
            },
            dateFormat: { type: 'date', format: 'yyyy-MM-dd' },
            dateVal : new Date(new Date().setHours(0, 0, 0, 0)),
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            data: [],
            groupOptions: {showDropArea: true, columns: [] },
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Sum: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },


            count:0,
            columns: {},
            answers: [{text: 'No', value: 'no'}, {text: 'Yes', value: 'yes'}],
            city_fields: { text: 'city_name' },
        }
    },
    provide: {
        grid: [ PdfExport, ExcelExport, Toolbar, Aggregate,Sort,Filter,Page,Group,],
    },
    created() {
        this.$root.preloader = false;
    },
    methods: {
        toolbarClick(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties() {
            return {
                fileName: "qso_export.pdf",
                pageOrientation: 'Landscape',
                pageSize: 'Legal'
            };
        },
        search() {
            var request = Object.assign({ apikey: this.$root.apikey }, this.filters);
            axios.get(`${this.$root.serverUrl}/admin/reports/needsLog`, {
                params: request,  json: true, }).then((resp) => {
                // if(resp) this.data = resp.data
                if(resp.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `error`, icon: 'error'})}
                else {
                    this.data = resp.data.data
                    // this.data.push({total:resp.data?.Count})
                    this.reportLoading = false;
                    this.showExportButton = true;
                }

                console.log(resp.data);
            })
        }
    }
}
</script>

<style scoped>

</style>
